import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.scss'],
})
export class GoogleReviewComponent {
  globalString: string = 'my scream';
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  closeDialog() {
    this.dialog.closeAll();
  }

  navigateToGoogleReview() {
    this.closeDialog();
    if (this.data.brandName !== 'Olive Mithai Shop') {
      this.copyScream();
    }
    window.open(this.data.url, '_blank');
  }

  copyScream() {
    const tempInput = document.createElement('input');
    tempInput.value = this.data.scream;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
}
