import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DeleteScreamComponent } from '../delete-scream/delete-scream.component';
import { TokenService } from '../../core/service/tokenService/token.service';
import { DataTransferService } from '../../core/service/data-transfer/data-transfer.service';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '../../core/service/userService/user.service';
import { ScreamService } from '../../core/service/screamService/scream.service';
import { SnackbarService } from '../../core/service/snackBar/snackbar.service';
import { BlockUserComponent } from '../block-user/block-user.component';
import { LoaderService } from '../../core/service/loader/loader.service';
import { Location } from '@angular/common';
import { SearchBarService } from '../../core/service/search/search-bar.service';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss'],
  providers: [MatButtonModule, MatBottomSheetModule],
})
export class BurgerMenuComponent implements OnInit {
  reportedSuccessfully: boolean = false;

  contentVisible!: boolean;
  isContent!: any;
  profile: any;
  myScream: any;
  purpose: any;
  reporter!: FormGroup;
  currentRoutePath: any;
  clickReport: boolean = false;
  isReported: boolean = false;
  openedReport: boolean = false;
  blockedSuccess: boolean = false;
  privacy!: string;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BurgerMenuComponent>,
    private userService: UserService,
    public dialog: MatDialog,
    private snackBarService: SnackbarService,
    public tokenService: TokenService,
    private dataTransfer: DataTransferService,
    public router: Router,
    private screamService: ScreamService,
    private readonly dataTransferService: DataTransferService,
    private loaderService: LoaderService,
    private location: Location,
    private searchbarService: SearchBarService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {}
  ngOnInit() {
    this.contentVisible = this.tokenService.getContent();
    this.reporter = this.reportForm();
    this.profile = JSON.parse(this.dataTransferService?.getUserDetails() || '');
    this.myScream =
      this.data.scream?.loginResponseDTO?.userName === this.profile?.userName
        ? true
        : false;
    this.contentVisible = this.tokenService.getContent();
    this.isContent = this.data;
    this.currentRoutePath = this.router.url;
  }

  reportForm(): FormGroup {
    return new FormGroup({
      report: new FormControl(''),
    });
  }

  clickedReport() {
    this.clickReport = true;
    this.openedReport = true;
  }

  isReportValid(): boolean {
    return this.reporter.value['report'].trim().length > 0;
  }

  reportScrem() {
    this.screamService.reportScream(this.reportBody()).subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 201) {
          this.reportedSuccessfully = true;
          this.dataTransfer.changeMessage('reported');
        }
      },
      error: (data) => {
        this.bottomSheetRef.dismiss();
        if (data.status == 0) {
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar',
          );
        } else {
          this.bottomSheetRef.dismiss();
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar',
          );
        }
      },
    });
  }

  reportUser() {
    this.userService.reportUser(this.reportBodyUser())?.subscribe({
      next: (data: any) => {
        if (data.body.responseCode == 201) {
          this.reportedSuccessfully = true;

          this.dataTransfer.changeMessage('reported');
        }
      },
      error: (data) => {
        if (data.status == 0) {
          this.bottomSheetRef.dismiss();
          this.snackBarService.openSnackBar(
            'Internal server error. Please try again!',
            'error-snackbar',
          );
        } else {
          this.bottomSheetRef.dismiss();
          this.snackBarService.openSnackBar(
            data.error.responseMessage,
            'error-snackbar',
          );
        }
      },
    });
  }

  reportBody() {
    return {
      reportedSuid: this.data.scream.suid,
      report: this.reporter?.value['report'].trim(),
    };
  }

  reportBodyUser() {
    return {
      reportedUuid: this.data.user,
      profile: this.data.profile?.userName,
      report: this.reporter?.value['report'].trim(),
    };
  }

  closeBottomSheet() {
    this.bottomSheetRef.dismiss();
  }

  openDelete() {
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(DeleteScreamComponent, {
      width: '300px',
      panelClass: ['border-radius1'],
      autoFocus: false,
      data: { scream: this.data.scream },
    });
  }

  openEditAddress(action: string) {
    this.bottomSheetRef.dismiss();
    this.router.navigate(['/edit-address'], {
      queryParams: { action: action },
    });
  }

  deleteAddress(action: string) {
    this.bottomSheetRef.dismiss();
    this.dialog.open(DeleteScreamComponent, {
      panelClass: ['border-radius'],
      autoFocus: false,
      data: { scream: action },
    });
  }

  blockUser() {
    const dialogRef = this.dialog.open(BlockUserComponent, {
      width: '348px',
      panelClass: ['blockuser'],
      autoFocus: true,
      data: { user: this.data.user, profile: this.data.profile },
    });
    this.closeBottomSheet();
    if (this.dataTransferService.isLoggedin()) {
      dialogRef?.afterClosed().subscribe((result) => {
        if (result === 'block') {
          this.loaderService.show();
          this.ngOnInit();
          this.userService.blockUser(this.data.user)?.subscribe({
            next: (res: any) => {
              this.loaderService.hide();
              if (res.body.responseCode === 200) {
                this.blockedSuccess = true;
                this.closeBottomSheet();
                this.snackBarService.openSnackBar(
                  'User blocked successfully',
                  'success-snackbar',
                );
                this.location.back();
                this.searchbarService.blockUser(this.data.profile?.userName);
              } else {
                this.snackBarService.openSnackBar(
                  'Failed to block user',
                  'error-snackbar',
                );
              }
            },
            error: (res) => {
              this.loaderService.hide();
              this.snackBarService.openSnackBar(
                res.error.responseMessage,
                'error-snackbar',
              );
            },
          });
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }
}
