<div *ngIf="data.scream !== 'private' && data.scream !== 'public'">
  <div class="pt-3">
    <div class="d-flex">
      <div>
        <mat-icon
          class="material-icons pointer pt-0"
          *ngIf="!reportedSuccessfully && !clickReport"
          (click)="closeBottomSheet()"
          >close</mat-icon
        >

        <div>
          <div class="d-flex flex-column text-center w-100">
            <p
              class="m-0 fs-23 fw-600 lato-font-family text-center w-100"
              *ngIf="!isReported && openedReport && !reportedSuccessfully"
            >
              Report
            </p>
          </div>

          <p
            class="m-0 fs-18 fw-500 lato-font-family scream-icon-color pl-15px pt-4"
            *ngIf="
              contentVisible &&
              !reportedSuccessfully &&
              !isReported &&
              openedReport
            "
          >
            Select a reason for reporting the scream
          </p>
          <p
            class="m-0 fs-18 fw-500 lato-font-family name-wrap lh-1 pt-4"
            *ngIf="
              !contentVisible &&
              !reportedSuccessfully &&
              !isReported &&
              openedReport
            "
          >
            <span class="scream-icon-color">Select a reason for reporting</span>
            <span class="font-color fw-500 ml-10px">{{
              data.profile?.userName
            }}</span>
          </p>
        </div>
      </div>

      <div
        class="d-flex justify-content-center fw-500 w-100"
        *ngIf="isContent.data !== 'address' && !clickReport"
      >
        <p class="h5 m-0 d-flex justify-content-center">
          {{ contentVisible ? "Scream options" : "Profile options" }}
        </p>
      </div>

      <div
        class="d-flex justify-content-center w-100"
        *ngIf="isContent.data === 'address'"
      >
        <p class="h5 text-center">Edit Options</p>
      </div>
    </div>

    <div class="my-3">
      <div *ngIf="!reportedSuccessfully && !isReported && openedReport">
        <form [formGroup]="reporter">
          <mat-radio-group formControlName="report">
            <ng-container *ngIf="contentVisible">
              <mat-radio-button value="Offensive language or Hate speech"
                >Offensive language or Hate speech</mat-radio-button
              >
              <mat-radio-button value="Irrelevant or Spam"
                >Irrelevant or Spam</mat-radio-button
              >
              <mat-radio-button value="Copied or Plagiarized content"
                >Copied or Plagiarized content</mat-radio-button
              >
              <mat-radio-button value="Promotional content"
                >Promotional content</mat-radio-button
              >
              <mat-radio-button
                value="Factually inaccurate or Misleading information"
                >Factually inaccurate or Misleading
                information</mat-radio-button
              >
            </ng-container>
            <ng-container *ngIf="!contentVisible">
              <mat-radio-button value="Spam">Spam</mat-radio-button>
              <mat-radio-button value="Impersonation"
                >Impersonation</mat-radio-button
              >
              <mat-radio-button value="Suspicious Activity"
                >Suspicious Activity</mat-radio-button
              >
              <mat-radio-button value="Inappropriate Behavior"
                >Inappropriate Behavior</mat-radio-button
              >
            </ng-container>
          </mat-radio-group>
          <div class="d-flex justify-content-end mt-3">
            <button
              mat-raised-button
              class="rounded-pill active-link br-color lato-font-family fw-600 fs-14 w-100 mx-2"
              (click)="closeBottomSheet()"
            >
              Cancel
            </button>
            <button
              mat-raised-button
              class="rounded-pill background-color white-color lato-font-family fw-600 fs-14 ms-2 w-100"
              type="submit"
              (click)="contentVisible ? reportScrem() : reportUser()"
              [class.disabled-button]="!isReportValid()"
              [disabled]="!isReportValid()"
            >
              Report
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="reportedSuccessfully">
      <div
        class="px-3 pl-32 d-flex flex-column align-items-center custom-bottom-sheet pt-0 pb-3"
      >
        <img
          src="assets/images/verified.svg"
          alt="brandCreated.svg"
          class="mb-2"
        />
        <h1 class="m-0 pb-2 font-color pt-0 fs-16 fw-700 lato-font-family">
          {{ contentVisible ? "Scream" : "Profile" }} reported successfully
        </h1>
        <p class="m-0 fw-400 fs-16 lato-font-family text-center">
          Thank you for reporting the
          {{ contentVisible ? "scream" : "profile" }}. Your feedback helps us
          maintain a positive environment for all users
        </p>
      </div>
      <div class="pt-2 pb-3">
        <button
          mat-raised-button
          #btnFocus="matButton"
          [autofocus]="btnFocus.focus()"
          type="submit"
          (click)="closeBottomSheet()"
          class="btn-primary background-color white-color lato-font-family py-2 px-3 fs-16 fw-500 w-100"
        >
          Okay
        </button>
      </div>
    </div>

    <div *ngIf="!reportedSuccessfully">
      <div
        class="d-flex align-content-center py-3 pointer"
        (click)="clickedReport()"
        *ngIf="!myScream && !clickReport && isContent.data !== 'address'"
      >
        <span class="material-symbols-outlined">report</span>
        <p class="h5 px-3 m-0">Report</p>
      </div>
    </div>

    <div *ngIf="!blockedSuccess && !contentVisible">
      <div
        *ngIf="!myScream && !clickReport && isContent.data !== 'address'"
        class="d-flex align-content-center lato-font-family py-3 pointer"
        (click)="blockUser()"
      >
        <span class="material-symbols-outlined">block</span>
        <p class="h5 px-3 m-0">Block</p>
      </div>
    </div>
    <div *ngIf="isContent.data !== 'address'">
      <div
        class="d-flex align-content-center py-4 pointer"
        *ngIf="myScream"
        (click)="openDelete()"
      >
        <i class="material-icons">delete_outline</i>
        <p class="h5 px-3 m-0">Delete</p>
      </div>
    </div>

    <div *ngIf="isContent.data === 'address'">
      <div
        class="d-flex pt-2 pointer screm-report"
        (click)="openEditAddress('edit')"
      >
        <span class="material-symbols-outlined">edit</span>
        <p class="h5 px-3">Edit</p>
      </div>
      <div
        class="d-flex pt-2 pointer screm-report"
        (click)="deleteAddress('delete')"
      >
        <span class="material-symbols-outlined">delete</span>
        <p class="h5 px-3">Delete</p>
      </div>
    </div>
  </div>
</div>

<div>
  <div *ngIf="!router.url?.startsWith('/business')">
    <div
      class="d-flex align-items-center pt-4 pb-32 pointer"
      *ngIf="data.scream === 'public'"
    >
      <mat-icon class="material-icons active-link">lock_open</mat-icon>
      <p class="fs-14 active-link px-1 m-0 fw-400">
        Everyone on Scremer can view this scream
      </p>
    </div>
    <div
      class="d-flex align-items-center pt-4 pb-32 pointer"
      *ngIf="data.scream === 'private'"
    >
      <mat-icon class="material-icons active-link">lock_outline</mat-icon>
      <p class="fs-14 active-link px-1 fw-400 m-0">
        This scream will be visible to you and the tagged business.
      </p>
    </div>
  </div>

  <div *ngIf="router.url?.startsWith('/business')">
    <div
      class="d-flex align-items-center pt-4 pb-32 pointer"
      *ngIf="data.scream === 'public'"
    >
      <mat-icon class="material-icons active-link">lock_open</mat-icon>
      <p class="fs-14 active-link px-1 m-0 fw-400">
        Everyone on Scremer can view this scream
      </p>
    </div>
    <div
      class="d-flex align-items-center pt-4 pb-32 pointer"
      *ngIf="data.scream === 'private'"
    >
      <mat-icon class="material-icons active-link">lock_outline</mat-icon>
      <p class="fs-14 active-link px-1 fw-400 m-0">
        This scream will be visible to you and user only.
      </p>
    </div>
  </div>
</div>
