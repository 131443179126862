<div class="s">
  <div class="px-3 pb-2 pt-3">
    <div class="">
      <div class="py-3">
        <mat-icon
          (click)="goBackToPreviousPage()"
          class="material-symbols-outlined pointer black-color fs-24 align-items-start"
        >
          close
        </mat-icon>
      </div>
      <div
        [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
        class="justify-content align-items"
      >
        <div class=" ">
          <div *ngIf="!showSuggestion">
            <div>
              <form
                (ngSubmit)="submit()"
                class="fs-18 fw-500 lato-font-family black-color"
              >
                <div class="py-3">
                  <p>How was the food taste?</p>
                  <div class="star-rating">
                    <ng-container
                      *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                    >
                      <span
                        class="material-icons"
                        [ngClass]="{ checked: i < foodTaste }"
                        (click)="foodTaste = i + 1; checkAllStarsGiven()"
                      >
                        star
                      </span>
                    </ng-container>
                  </div>
                </div>

                <div class="py-3">
                  <p>How was the service?</p>
                  <div class="star-rating">
                    <ng-container
                      *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                    >
                      <span
                        class="material-icons"
                        [ngClass]="{ checked: i < service }"
                        (click)="service = i + 1; checkAllStarsGiven()"
                      >
                        star
                      </span>
                    </ng-container>
                  </div>
                </div>

                <div class="py-3">
                  <p class="">How does our shop look?</p>
                  <div class="star-rating">
                    <ng-container
                      *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                    >
                      <span
                        class="material-icons"
                        [ngClass]="{ checked: i < shopLook }"
                        (click)="shopLook = i + 1; checkAllStarsGiven()"
                      >
                        star
                      </span>
                    </ng-container>
                  </div>
                </div>

                <div class="py-3">
                  <p>How was your overall experience?</p>
                  <div class="star-rating">
                    <ng-container
                      *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                    >
                      <span
                        class="material-icons"
                        [ngClass]="{ checked: i < overall }"
                        (click)="overall = i + 1; checkAllStarsGiven()"
                      >
                        star
                      </span>
                    </ng-container>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div *ngIf="showSuggestion">
            <div>
              <mat-label class="fs-18 fw-500 lato-font-family black-color mb-4">
                Any Improvement Suggestions?
              </mat-label>
              <div appearance="" class="mt-4">
                <mat-form-field appearance="outline" class="my-2 box">
                  <textarea
                    matInput
                    [(ngModel)]="suggestionText"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="8"
                    cols="15"
                    placeholder="Type here"
                    maxlength="500"
                    border-radius="5"
                    class="suggestion-textarea"
                    box
                    #suggestion
                  ></textarea>
                  <mat-hint align="end"
                    >{{ suggestion.value.length }} / 500</mat-hint
                  >
                </mat-form-field>
              </div>
              <div class="d-flex justify-content-end mt-1 mb-2">
                <div class="px-3">
                  <button
                    class="btn btn-primary active-link background-white-color lato-font-family fw-600 fs-16 px-4 py-2"
                    (click)="skipSuggestion()"
                  >
                    Skip
                  </button>
                </div>

                <button
                  type="submit"
                  [ngClass]="{
                    'disabled-button': !suggestionText,
                    'enabled-button': suggestionText,
                  }"
                  (click)="submit()"
                  class="btn-primary background-color white-color lato-font-family fw-600 fs-16 px-4 py-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pt">
        <div class="d-flex profile-container-height">
          <div
            class="d-flex align-items-center justify-content-center"
            *ngIf="!isSliderSelected"
          >
            <img
              class="profile-image-32 profile-border rounded-object p-0 object-contain"
              src="/assets/images/Olive-Mithai-logo.png"
              alt="brand-image"
            />
          </div>
          <div class="row" *ngIf="!showSuggestion">
            <p class="fs-18 fw-400 scream-icon-color pt-4 mx-3 pb-2">
              How are you feeling with Olive Mithai Shop?
            </p>
          </div>
          <div
            [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
            class="d-flex mx-2"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="toolbarSticky px-3 pb-3 pos" *ngIf="!showSuggestion">
    <div class="pt-">
      <app-hate-rate-slider
        [slider]="this.data?.event || 0"
        (sliderValue)="getSliderValue($event)"
      ></app-hate-rate-slider>
    </div>
    <div>
      <div
        class="row px-3 py-3"
        [ngClass]="isSliderSelected ? '' : 'carousel-indicators'"
      >
        <div
          class="pt-4 text-center"
          [ngClass]="{
            'top-position': screenWidth > 576,
            'bottom-position': screenWidth <= 576,
          }"
        >
          <button
            [ngClass]="{
              'disabled-button': !isstarchecked,
              'enabled-button': isstarchecked,
            }"
            (click)="continue()"
            class="btn-primary background-color white-color lato-font-family fs-16 fw-600 px-4 py-2"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
