import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppConfig } from '../../config/app.config';
import { MatDialog } from '@angular/material/dialog';
import { CreateAScreamComponent } from 'src/app/scream/create-a-scream/create-a-scream.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScreamService {
  private isRequesting: boolean = false;
  private scream: any;
  private api = AppConfig.getAPIURI() + 'scream';
  private api1 = AppConfig.getAPIURI() + 'report';
  private api3 = AppConfig.getAPIURI() + 'rating';
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private meta: Meta,
    private router: Router,
    @Inject(DOCUMENT) private document: any,
  ) {}

  setCanonicalUrl(url: string): void {
    let linkElement = this.document.querySelector('link[rel="canonical"]');
    if (!linkElement) {
      linkElement = this.document.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      this.document.head.appendChild(linkElement);
    }
    linkElement.setAttribute('href', url);
  }

  init(): void {
    this.router.events.subscribe(() => {
      const canonicalUrl = window.location.origin + this.router.url;
      this.setCanonicalUrl(canonicalUrl);
    });
  }
  postScream(scream: any, params: any): Observable<any> {
    return this.http.post(this.api + '/save-scream', scream, {
      observe: 'response',
      params: params,
    });
  }

  fetchMyScreams(request: any): Observable<any> {
    const params = request;
    return this.http.get(this.api + '/get-my-screams', {
      params,
      observe: 'response',
    });
  }
  deleteScream(suid: any): Observable<any> {
    return this.http.delete(this.api + `/delete-scream/${suid}`, {
      observe: 'response',
    });
  }
  setScream(scream: any) {
    this.scream = scream;
  }

  getScream() {
    return this.scream;
  }

  fetchUserScreams(userName: any, request: any): Observable<any> {
    const params = request;
    return this.http.get(this.api + `/get-user-screams/${userName}`, {
      params,
      observe: 'response',
    });
  }

  screamUp(suid: any): Promise<any> {
    if (this.isRequesting) {
      return Promise.resolve();
    }
    this.isRequesting = true;
    return this.http
      .post(this.api + `/scream-up/${suid}`, {
        observe: 'response',
      })
      .toPromise()
      .finally(() => {
        this.isRequesting = false;
      });
  }
  fetchScreamBySuid(suid: any): Observable<any> {
    return this.http.get(this.api + `/fetch-scream/${suid}`, {
      observe: 'response',
    });
  }
  fetchHomeScream(params: any): Observable<any> {
    return this.http.get(this.api + `/fetch-home-scream`, {
      params,
      observe: 'response',
    });
  }
  reportScream(data: any): Observable<any> {
    return this.http.post(this.api1 + '/scream', data, {
      observe: 'response',
    });
  }
  fetchScreamUps(suid: any, params: any): Observable<any> {
    return this.http.get(this.api + `/fetch-screamups/${suid}`, {
      params,
      observe: 'response',
    });
  }
  resolveScream(suid: any, index: number): Observable<any> {
    return this.http.post(this.api + `/save-experience/${suid}`, null, {
      params: { experienceValue: index },
      observe: 'response',
    });
  }

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<any>(
    'default',
  );
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
  getPreviousUrlValue(): string {
    return this.previousUrl.value;
  }

  updateScreamStatus(params: any): Observable<any> {
    return this.http.put(
      this.api + '/update-status',
      {},
      {
        params,
        observe: 'response',
      },
    );
  }

  fetchContact(params: any): Observable<any> {
    return this.http.get(this.api + `/contact/${params?.suid}`, {
      observe: 'response',
    });
  }

  saveScanLog(params: any): Observable<any> {
    return this.http.post(
      this.api + '/log-scan',
      {},
      {
        params,
        observe: 'response',
      },
    );
  }

  postRating(rating: any, params: any): Observable<any> {
    return this.http.post(this.api3 + '/save-review-rating', rating, {
      observe: 'response',
      params: params,
    });
  }
}
