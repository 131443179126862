import { MaterialModule } from '../core/material/material.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { OneTimePasswordComponent } from './one-time-password/one-time-password.component';
import { ScreamComponent } from './scream/scream.component';
import { FeatureRoutingModule } from '../feature/feature-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScreamRoutingModule } from '../scream/scream-routing.module';
import { HateRateSliderComponent } from './hate-rate-slider/hate-rate-slider.component';
import { ScreamToolbarComponent } from './scream-toolbar/scream-toolbar.component';
import { ShimmerModule } from '../shimmer/shimmer.module';
import { ToolbarComponent } from './scream/components/toolbar/toolbar.component';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { OtpService } from '../core/service/otpService/otp.service';
import { ProfileComponent } from './profile/profile.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { BackArrowComponent } from './back-arrow/back-arrow.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { DeleteScreamComponent } from './delete-scream/delete-scream.component';
import { BurgerMenuComponent } from './burger-menu/burger-menu.component';
import { ReportComponent } from './report/report.component';
import { ResponseListComponent } from './response-list/response-list.component';
import { FollowersComponent } from './followers/followers.component';
import { ScreamTagComponent } from './scream-tag/scream-tag.component';
import { TimestampPipe } from './pipes/timestamp/timestamp-Pipe';
import { Count } from './pipes/count/count-Pipe';
import { ShareComponent } from './share/share.component';
import { AssuranceMeterComponent } from './assurance-meter/assurance-meter.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { ScreamFieldComponent } from './scream-field/scream-field.component';
import { ScreamUpsComponent } from './scream-ups/scream-ups.component';
import { ProfileAndHateRateSliderComponent } from './profile-and-hate-rate-slider/profile-and-hate-rate-slider.component';
import { SearchComponent } from './search/search.component';
import { SearchHistoryComponent } from './search/search-history/search-history.component';
import { SearchResultsComponent } from './search/search-results/search-results.component';
import { ScreamStatusConfirmationComponent } from './scream-status-confirmation/scream-status-confirmation.component';
import { ResolveScreamComponent } from './resolve-scream/resolve-scream.component';
import { LogoutComponent } from './logout/logout.component';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { PositiveFeedbackComponent } from './positive-feedback/positive-feedback.component';
import { EditBusinessDetailsComponent } from './edit-profile/edit-business-details/edit-business-details.component';
import { EditUserDetailsComponent } from './edit-profile/edit-user-details/edit-user-details.component';
import { GoogleSsoButtonComponent } from './google-sso-button/google-sso-button.component';
import { SaveDraftComponent } from './save-draft/save-draft.component';
import { ViewContactComponent } from './scream/view-contact/view-contact.component';
import { MatRadioModule } from '@angular/material/radio';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { FooterComponent } from './footer/footer.component';
import { ReportSubmitComponent } from './report-submit/report-submit.component';
import { ReportUserSubmitComponent } from './report-user-submit/report-user-submit.component';
import { BlockUserComponent } from './block-user/block-user.component';
import { BrandBranchLocationComponent } from './brand-branch-location/brand-branch-location.component';
import { ShippingPolicyComponent } from './shipping-policy/shipping-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CancelPlanComponent } from './cancel-plan/cancel-plan.component';
import { ReviewRatingUserFlowComponent } from './review-rating-user-flow/review-rating-user-flow.component';

@NgModule({
  declarations: [
    OneTimePasswordComponent,
    ScreamComponent,
    ToolbarComponent,
    ScreamToolbarComponent,
    HateRateSliderComponent,
    ProfileComponent,
    UploadImageComponent,
    BackArrowComponent,
    ImagePreviewComponent,
    LogoutComponent,
    ResponseListComponent,
    DeleteScreamComponent,
    BurgerMenuComponent,
    ReportComponent,
    FollowersComponent,
    ScreamTagComponent,
    TimestampPipe,
    Count,
    ShareComponent,
    AssuranceMeterComponent,
    ScreamFieldComponent,
    ScreamUpsComponent,
    ProfileAndHateRateSliderComponent,
    SearchComponent,
    SearchHistoryComponent,
    SearchResultsComponent,
    ScreamStatusConfirmationComponent,
    ResolveScreamComponent,
    UploadDocComponent,
    PositiveFeedbackComponent,
    EditBusinessDetailsComponent,
    EditUserDetailsComponent,
    GoogleSsoButtonComponent,
    SaveDraftComponent,
    ViewContactComponent,
    RefundPolicyComponent,
    FooterComponent,
    ReportSubmitComponent,
    ReportUserSubmitComponent,
    BlockUserComponent,
    BrandBranchLocationComponent,
    ShippingPolicyComponent,
    ContactUsComponent,
    CancelPlanComponent,
    ReviewRatingUserFlowComponent,
  ],
  imports: [
    MaterialModule,
    NgbModule,
    MatRadioModule,
    CommonModule,
    FormsModule,
    FeatureRoutingModule,
    ScreamRoutingModule,
    ReactiveFormsModule,
    ShimmerModule,
    InfiniteScrollModule,
    NgbModalModule,
    NgxGaugeModule,
  ],
  exports: [
    OneTimePasswordComponent,
    ScreamComponent,
    ScreamToolbarComponent,
    HateRateSliderComponent,
    NgbModule,
    CommonModule,
    MaterialModule,
    ProfileComponent,
    UploadImageComponent,
    BackArrowComponent,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    ShimmerModule,
    FollowersComponent,
    ResponseListComponent,
    ScreamTagComponent,
    TimestampPipe,
    AssuranceMeterComponent,
    ScreamFieldComponent,
    ProfileAndHateRateSliderComponent,
    SearchComponent,
    SearchHistoryComponent,
    SearchResultsComponent,
    ScreamStatusConfirmationComponent,
    ResolveScreamComponent,
    LogoutComponent,
    EditBusinessDetailsComponent,
    EditUserDetailsComponent,
    GoogleSsoButtonComponent,
    UploadDocComponent,
    NgxGaugeModule,
    FooterComponent,
    BrandBranchLocationComponent,
  ],

  providers: [OtpService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
